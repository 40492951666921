import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  min-height: ${props => props.minHeight};
  min-width: ${props => props.minWidth};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  background: ${props => props.background};
  justify-content: ${props => props.justify};
  align-items: ${props => props.align};
  align-self: ${props => props.alignSelf};
  flex-direction: ${props => (props.column ? 'column' : props.direction)};
  width: ${props => (props.fullWidth ? '100%' : props.width)};
  max-width: ${props => props.maxWidth};
  flex: ${props => props.flex};
  flex-grow: ${props => props.grow};
  flex-shrink: ${props => props.shrink || 0};
  height: ${props => (props.fullHeight ? '100%' : props.height)};
  max-height: ${props => props.maxHeight};
  flex-basis: ${props => props.basis};
  flex-flow: ${props => props.wrap};
  z-index: ${props => props.zIndex};
  box-shadow: ${props => props.boxShadow};
  border: ${props => props.border};
  border-bottom: ${props => props.borderBottom};
  border-radius: ${props => props.borderRadius};
  overflow: ${props => props.overflow};
  position: ${props => props.position};
  top: ${props => props.top};
  right: ${props => props.right};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  flex-wrap: ${props => props.wrap};
  color: ${props => props.color};
  cursor: ${props => props.cursor};
  opacity: ${props => props.opacity};
  gap: ${props => props.gap};
  ${props =>
    props.center &&
    `
    justify-content: center;
    align-items: center;
  `};
`;

export default Flex;
