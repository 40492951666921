import { fromJS } from 'immutable';
import * as types from '../constants/ActionTypes';
import { setToLocalStorage } from '../utils/localStorageHelpers';

const initialState = fromJS({
  submit: {
    isSending: false,
    isSendingError: false,
    error: null,
    data: null,
    confirmation: {
      isOpen: false
    }
  },
  modes: {
    isFetching: false,
    isFetchingError: false
  },
  change: {
    isSending: false,
    isSendingError: false,
    is2FAChangePopupOpen: false,
    onChangeCompleteCallback: null
  },
  data: {}
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.TWOFA_SET:
      return state.merge({
        data: action.data
      });

    case types.TWOFA_RESET:
      return state.merge({
        data: {},
        submit: {}
      });
    case types.TWOFA_SUBMIT_RESET: 
      return state.mergeIn(['submit'], {
        isSending: false,
        isSendingError: false,
        error: null
      })
    case types.TWOFA_SUBMIT_INIT:
      return state.mergeIn(['submit'], {
        isSending: true,
        isSendingError: false,
        error: null
      });

    case types.TWOFA_SUBMIT_SUCCESS:
      // Setting access key and secret key to localstorage for future access to auth credentials.
      if (action.data && action.data.access_key) {
        setToLocalStorage('accessKey', action.data.access_key);
        setToLocalStorage('secretKey', action.data.secret_key);
      }
      return state.mergeIn(['submit'], {
        isSending: false,
        isSendingError: false,
        error: null
      });
    case types.TWOFA_SUBMIT_OPEN_CONFIRMATION:
      return state.mergeIn(['submit', 'confirmation'], {
        isOpen: true,
        title: action.title,
        message: action.message
      });
    case types.TWOFA_SUBMIT_CLOSE_CONFIRMATION:
      return state.mergeIn(['submit', 'confirmation'], {
        isOpen: false
      });

    case types.TWOFA_SUBMIT_ERROR:
      return state.mergeIn(['submit'], {
        isSending: false,
        isSendingError: true,
        error: action.error
      });

    case types.INIT_2FA_CHANGE_INIT:
      return state.mergeIn(['change'], {
        isSending: true,
        source: action.source
      });
    case types.INIT_2FA_CHANGE_SUCCESS:
      return state.mergeIn(['change'], {
        isSending: false,
        isSendingError: false,
        data: action.data
      });
    case types.INIT_2FA_CHANGE_ERROR:
      return state.mergeIn(['change'], {
        isSending: false,
        isSendingError: true,
        message: action.message
      });
    case types.VERIFY_2FA_CHANGE_INIT:
      return state.mergeIn(['change'], {
        isSending: true
      });
    case types.VERIFY_2FA_CHANGE_SUCCESS:
      return state.mergeIn(['change'], {
        isSending: false,
        isSendingError: false,
        data: action.data
      });
    case types.VERIFY_2FA_CHANGE_ERROR:
      return state.mergeIn(['change'], {
        isSending: false,
        isSendingError: true,
        message: action.message
      });
    case types.CONFIRM_2FA_CHANGE_INIT:
      return state.mergeIn(['change'], {
        isSending: true,
        isSendingError: false,
        message: null
      });
    case types.CONFIRM_2FA_CHANGE_SUCCESS:
      return state
        .mergeIn(['change', 'data'], action.data)
        .mergeIn(['change'], {
          isSending: false,
          isSendingError: false
        });
    case types.CONFIRM_2FA_CHANGE_ERROR:
      return state.mergeIn(['change'], {
        isSending: false,
        isSendingError: true,
        message: action.message
      });
    case types.RESET_2FA_CHANGE: {
      return state.mergeIn(['change'], {
        isSending: false,
        isSendingError: false,
        is2FAChangePopupOpen: false,
        data: {},
        message: null
      });
    }
    case types.SET_2FA_CHANGE_POPUP: {
      return state.mergeIn(['change'], {
        is2FAChangePopupOpen: action.is2FAChangePopupOpen
      });
    }
    case types.FETCH_2FA_MODES_INIT: {
      return state.mergeIn(['modes'], {
        isFetching: true,
        isFetchingError: false,
        message: null
      });
    }
    case types.FETCH_2FA_MODES_SUCCESS: {
      return state.mergeIn(['modes'], {
        isFetching: false,
        isFetchingError: false,
        data: action.data.modes
      });
    }
    case types.FETCH_2FA_MODES_ERROR: {
      return state.mergeIn(['modes'], {
        isFetching: false,
        isFetchingError: true,
        message: action.message
      });
    }
    case types.RECOVER_2FA_INIT: {
      return state.mergeIn(['change'], {
        isSending: true,
        isSendingError: false,
        message: null
      });
    }
    case types.RECOVER_2FA_SUCCESS: {
      return state.mergeIn(['change'], {
        isSending: false,
        isSendingError: false,
        data: action.data
      });
    }
    case types.RECOVER_2FA_ERROR: {
      return state.mergeIn(['change'], {
        isSending: false,
        isSendingError: true,
        message: action.message
      });
    }
    default:
      return state;
  }
}
