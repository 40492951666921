export const FETCH_BANK_DETAILS_INIT = 'FETCH_BANK_DETAILS_INIT';
export const FETCH_BANK_DETAILS_SUCCESS = 'FETCH_BANK_DETAILS_SUCCESS';
export const FETCH_BANK_DETAILS_ERROR = 'FETCH_BANK_DETAILS_ERROR';

export const SUBMIT_BANK_DETAILS_INIT = 'SUBMIT_BANK_DETAILS_INIT';
export const SUBMIT_BANK_DETAILS_SUCCESS = 'SUBMIT_BANK_DETAILS_SUCCESS';
export const SUBMIT_BANK_DETAILS_ERROR = 'SUBMIT_BANK_DETAILS_ERROR';

export const SUBMIT_DEPOSIT_INIT = 'SUBMIT_DEPOSIT_INIT';
export const SUBMIT_DEPOSIT_SUCCESS = 'SUBMIT_DEPOSIT_SUCCESS';
export const SUBMIT_DEPOSIT_ERROR = 'SUBMIT_DEPOSIT_ERROR';
export const RESET_DEPOSIT_DATA = 'RESET_DEPOSIT_DATA';

export const INR_WITHDRAWAL_INIT = 'INR_WITHDRAWAL_INIT';
export const INR_WITHDRAWAL_SUCCESS = 'INR_WITHDRAWAL_SUCCESS';
export const INR_WITHDRAWAL_ERROR = 'INR_WITHDRAWAL_ERROR';

export const PLACE_ORDER_INIT = 'PLACE_ORDER_INIT';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const SET_PLACED_ORDER_ID = 'SET_PLACED_ORDER_ID';
export const RESET_PLACED_ORDER_ID = 'RESET_PLACED_ORDER_ID';
export const PLACE_ORDER_ERROR = 'PLACE_ORDER_ERROR';

export const FETCH_ORDER_DETAIL_INIT = 'FETCH_ORDER_DETAI';
export const FETCH_ORDER_DETAIL_SUCCESS = 'FETCH_ORDER_DETAIL_SUCCESS';
export const FETCH_ORDER_DETAIL_ERROR = 'FETCH_ORDER_DETAIL_ERROR';

export const VERIFICATION_STAGES_INIT = 'VERIFICATION_STAGES_INIT';
export const VERIFICATION_STAGES_UPDATE = 'VERIFICATION_STAGES_UPDATE';
export const SEND_OTP_INIT = 'SEND_OTP_INIT';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_ERROR = 'SEND_OTP_ERROR';
export const RESET_SEND_OTP = 'RESET_SEND_OTP';

export const RESEND_OTP_INIT = 'RESEND_OTP_INIT';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_ERROR = 'RESEND_OTP_ERROR';
export const RESET_RESEND_OTP = 'RESET_RESEND_OTP';

export const SUBMIT_OTP_INIT = 'SUBMIT_OTP_INIT';
export const SUBMIT_OTP_SUCCESS = 'SUBMIT_OTP_SUCCESS';
export const SUBMIT_OTP_ERROR = 'SUBMIT_OTP_ERROR';
export const SUBMIT_KYC_INIT = 'SUBMIT_KYC_INIT';
export const SUBMIT_KYC_SUCCESS = 'SUBMIT_KYC_SUCCESS';
export const SUBMIT_KYC_ERROR = 'SUBMIT_KYC_ERROR';
export const RESET_SUBMIT_KYC = 'RESET_SUBMIT_KYC';
export const RESTART_KYC_SUBMISSION = 'RESTART_KYC_SUBMISSION';
export const GET_KYC_INIT = 'GET_KYC_INIT';
export const GET_KYC_SUCCESS = 'GET_KYC_SUCCESS';
export const GET_KYC_ERROR = 'GET_KYC_ERROR';

export const TWOFA_SET = 'TWOFA_SET';
export const TWOFA_RESET = 'TWOFA_RESET';
export const TWOFA_SUBMIT_RESET = 'TWOFA_SUBMIT_RESET';
export const TWOFA_SUBMIT_INIT = 'TWOFA_SUBMIT_INIT';
export const TWOFA_SUBMIT_SUCCESS = 'TWOFA_SUBMIT_SUCCESS';
export const TWOFA_SUBMIT_ERROR = 'TWOFA_SUBMIT_ERROR';
export const TWOFA_SUBMIT_OPEN_CONFIRMATION = 'TWOFA_SUBMIT_OPEN_CONFIRMATION';
export const TWOFA_SUBMIT_CLOSE_CONFIRMATION =
  'TWOFA_SUBMIT_CLOSE_CONFIRMATION';

export const BUILD_DEPOSIT_ADDRESS_SCHEMA = 'BUILD_DEPOSIT_ADDRESS_SCHEMA';
export const FETCH_DEPOSIT_ADDRESS_INIT = 'FETCH_DEPOSIT_ADDRESS_INIT';
export const FETCH_DEPOSIT_ADDRESS_SUCCESS = 'FETCH_DEPOSIT_ADDRESS_SUCCESS';
export const FETCH_DEPOSIT_ADDRESS_ERROR = 'FETCH_DEPOSIT_ADDRESS_ERROR';

export const SUBMIT_WITHDRAWAL_INIT = 'SUBMIT_WITHDRAWAL_INIT';
export const SUBMIT_WITHDRAWAL_SUCCESS = 'SUBMIT_WITHDRAWAL_SUCCESS';
export const SUBMIT_WITHDRAWAL_ERROR = 'SUBMIT_WITHDRAWAL_ERROR';
export const RESET_WITHDRAWAL_DATA = 'RESET_WITHDRAWAL_DATA';

export const SIGN_IN_INIT = 'SIGN_IN_INIT';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SET_SIGN_IN_ERROR = 'SET_SIGN_IN_ERROR';

export const INITIATE_RESET_PASSWORD_INIT = 'INITIATE_RESET_PASSWORD_INIT';
export const INITIATE_RESET_PASSWORD_SUCCESS =
  'INITIATE_RESET_PASSWORD_SUCCESS';
export const INITIATE_RESET_PASSWORD_ERROR = 'INITIATE_RESET_PASSWORD_ERROR';

export const VERIFY_TOKEN_INIT = 'VERIFY_TOKEN_INIT';
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_ERROR = 'VERIFY_TOKEN_ERROR';

export const RESET_PASSWORD_INIT = 'RESET_PASSWORD_INIT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const RESET_LOGIN = 'RESET_LOGIN';
export const RESET_SIGNUP = 'RESET_SIGNUP';
export const RESET_INITIATE_RESET_PASSWORD = 'RESET_INITIATE_RESET_PASSWORD';

export const SIGN_UP_INIT = 'SIGN_UP_INIT';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const SIGN_OUT_INIT = 'SIGN_OUT_INIT';
export const SIGN_OUT_COMPLETE = 'SIGN_OUT_COMPLETE';

export const RESEND_EMAIL_INIT = 'RESEND_EMAIL_INIT';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_ERROR = 'RESEND_EMAIL_ERROR';

export const SIGNUP_OTP_REQUEST_INIT = 'SIGNUP_OTP_REQUEST_INIT';
export const SIGNUP_OTP_REQUEST_SUCCESS = 'SIGNUP_OTP_REQUEST_SUCCESS';
export const SIGNUP_OTP_REQUEST_ERROR = 'SIGNUP_OTP_REQUEST_ERROR';

export const FETCH_REFERRAL_CODE_SUCCESS = 'FETCH_REFERRAL_CODE_SUCCESS';
export const FETCH_REFERRAL_CODE_ERROR = 'FETCH_REFERRAL_CODE_ERROR';
export const FETCH_REFERRALS_COUNT_SUCCESS = 'FETCH_REFERRALS_COUNT_SUCCESS';
export const FETCH_REFERRALS_COUNT_ERROR = 'FETCH_REFERRALS_COUNT_ERROR';
export const FETCH_USER_PROFILE_INIT = 'FETCH_USER_PROFILE_INIT';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';
export const RESET_USER_DATA = 'RESET_USER_DATA';
export const FETCH_CONTEST_DATA_INIT = 'FETCH_CONTEST_DATA_INIT';
export const FETCH_CONTEST_DATA_SUCCESS = 'FETCH_CONTEST_DATA_SUCCESS';
export const FETCH_CONTEST_DATA_ERROR = 'FETCH_CONTEST_DATA_ERROR';

export const FETCH_TRANSACTION_HISTORY_INIT = 'FETCH_TRANSACTION_HISTORY_INIT';
export const FETCH_TRANSACTION_HISTORY_SUCCESS =
  'FETCH_TRANSACTION_HISTORY_SUCCESS';
export const FETCH_TRANSACTION_HISTORY_ERROR =
  'FETCH_TRANSACTION_HISTORY_ERROR';

export const FETCH_LIVE_WITHDRAWAL = 'FETCH_LIVE_WITHDRAWAL';
export const FETCH_LIVE_DEPOSIT = 'FETCH_LIVE_DEPOSIT';
export const UPDATE_FUNDS_STATUS = 'UPDATE_FUNDS_STATUS';

export const FETCH_ORDER_BOOK_INIT = 'FETCH_ORDER_BOOK_INIT';
export const FETCH_ORDER_BOOK_SUCCESS = 'FETCH_ORDER_BOOK_SUCCESS';
export const FETCH_ORDER_BOOK_ERROR = 'FETCH_ORDER_BOOK_ERROR';
export const FETCH_ORDER_BOOK_LIVE = 'FETCH_ORDER_BOOK_LIVE';

export const FETCH_ORDERS_INIT = 'FETCH_ORDERS_INIT';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR';
export const FETCH_ORDERS_LIVE = 'FETCH_ORDERS_LIVE';
export const FETCH_ORDERS_NEXT = 'FETCH_ORDERS_NEXT';
export const SLICE_COMPLETED_ORDERS = 'SLICE_COMPLETED_ORDERS';

export const FETCH_CONFIG_INIT = 'FETCH_CONFIG_INIT';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_ERROR = 'FETCH_CONFIG_ERROR';

export const FETCH_FUNDS_INIT = 'FETCH_FUNDS_INIT';
export const FETCH_FUNDS_SUCCESS = 'FETCH_FUNDS_SUCCESS';
export const FETCH_FUNDS_ERROR = 'FETCH_FUNDS_ERROR';
export const UPDATE_FUNDS = 'UPDATE_FUNDS';

export const FETCH_TICKER_INIT = 'FETCH_TICKER_INIT';
export const FETCH_TICKER_SUCCESS = 'FETCH_TICKER_SUCCESS';
export const FETCH_TICKER_ERROR = 'FETCH_TICKER_ERROR';
export const FETCH_TICKER_LIVE = 'FETCH_TICKER_LIVE';

export const CANCEL_ORDER_INIT = 'CANCEL_ORDER_INIT';
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR';

export const CANCEL_ALL_ORDER_INIT = 'CANCEL_ALL_ORDER_INIT';
export const CANCEL_ALL_ORDER_SUCCESS = 'CANCEL_ALL_ORDER_SUCCESS';
export const CANCEL_ALL_ORDER_ERROR = 'CANCEL_ALL_ORDER_ERROR';

export const FETCH_TRADE_HISTORY_INIT = 'FETCH_TRADE_HISTORY_INIT';
export const FETCH_TRADE_HISTORY_SUCCESS = 'FETCH_TRADE_HISTORY_SUCCESS';
export const FETCH_TRADE_HISTORY_ERROR = 'FETCH_TRADE_HISTORY_ERROR';
export const FETCH_TRADE_HISTORY_LIVE = 'FETCH_TRADE_HISTORY_LIVE';

export const SAVE_EMAIL = 'SAVE_EMAIL';
export const RESET_SAVE_EMAIL = 'RESET_SAVE_EMAIL';

export const ACTIVATE_INIT = 'ACTIVATE_INIT';
export const ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS';
export const ACTIVATE_ERROR = 'ACTIVATE_ERROR';

export const RESET_EXCHANGE_DATA = 'RESET_EXCHANGE_DATA';
export const RESET_ORDER_DATA = 'RESET_ORDER_DATA';
export const RESET_TRANSFER_DATA = 'RESET_TRANSFER_DATA';
export const RESET_FUNDS = 'RESET_FUNDS';
export const RESET_ONBOARDING_STATE = 'RESET_ONBOARDING_STATE';

export const NOTIFICATION_SEND = 'NOTIFICATION_SEND';
export const NOTIFICATION_CLEAR = 'NOTIFICATION_CLEAR';
export const NOTIFICATION_DISMISS = 'NOTIFICATION_DISMISS';

export const SET_IS_PAGE_VISIBLE = 'SET_IS_PAGE_VISIBLE';
export const SET_SOCKET_STATES = 'SET_SOCKET_STATES';

export const FETCH_WRX_CURRENT_REWARDS_INIT = 'FETCH_WRX_CURRENT_REWARDS_INIT';
export const FETCH_WRX_CURRENT_REWARDS_SUCCESS =
  'FETCH_WRX_CURRENT_REWARDS_SUCCESS';
export const FETCH_WRX_CURRENT_REWARDS_ERROR =
  'FETCH_WRX_CURRENT_REWARDS_ERROR';
export const FETCH_WRX_REWARDS_HISTORY_INIT = 'FETCH_WRX_REWARDS_HISTORY_INIT';
export const FETCH_WRX_REWARDS_HISTORY_SUCCESS =
  'FETCH_WRX_REWARDS_HISTORY_SUCCESS';
export const FETCH_WRX_REWARDS_HISTORY_ERROR =
  'FETCH_WRX_REWARDS_HISTORY_ERROR';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';

export const P2P_PLACE_ORDER_INIT = 'P2P_PLACE_ORDER_INIT';
export const P2P_PLACE_ORDER_SUCCESS = 'P2P_PLACE_ORDER_SUCCESS';
export const P2P_SET_PLACED_ORDER_ID = 'P2P_SET_PLACED_ORDER_ID';
export const P2P_RESET_PLACED_ORDER_ID = 'P2P_RESET_PLACED_ORDER_ID';
export const P2P_PLACE_ORDER_ERROR = 'P2P_PLACE_ORDER_ERROR';
export const P2P_FETCH_PRICE_INIT = 'P2P_FETCH_PRICE_INIT';
export const P2P_FETCH_PRICE_SUCCESS = 'P2P_FETCH_PRICE_SUCCESS';
export const P2P_FETCH_PRICE_ERROR = 'P2P_FETCH_PRICE_ERROR';

export const P2P_FETCH_ORDERS_INIT = 'P2P_FETCH_ORDERS_INIT';
export const P2P_FETCH_ORDERS_SUCCESS = 'P2P_FETCH_ORDERS_SUCCESS';
export const P2P_FETCH_ORDERS_ERROR = 'P2P_FETCH_ORDERS_ERROR';

export const P2P_FETCH_ORDER_DETAIL_INIT = 'P2P_FETCH_ORDER_DETAIL_INIT';
export const P2P_FETCH_ORDER_DETAIL_SUCCESS = 'P2P_FETCH_ORDER_DETAIL_SUCCESS';
export const P2P_FETCH_ORDER_DETAIL_ERROR = 'P2P_FETCH_ORDER_DETAIL_ERROR';

export const P2P_CANCEL_ORDER_INIT = 'P2P_CANCEL_ORDER_INIT';
export const P2P_CANCEL_ORDER_SUCCESS = 'P2P_CANCEL_ORDER_SUCCESS';
export const P2P_CANCEL_ORDER_ERROR = 'P2P_CANCEL_ORDER_ERROR';

export const P2P_CANCEL_PAYMENT_INIT = 'P2P_CANCEL_PAYMENT_INIT';
export const P2P_CANCEL_PAYMENT_SUCCESS = 'P2P_CANCEL_PAYMENT_SUCCESS';
export const P2P_CANCEL_PAYMENT_ERROR = 'P2P_CANCEL_PAYMENT_ERROR';

export const P2P_UPDATE_ORDER_STATUS = 'P2P_UPDATE_ORDER_STATUS';
export const P2P_UPDATE_ORDER_STATUS_START = 'P2P_UPDATE_ORDER_STATUS_START';
export const P2P_UPDATE_ORDER_STATUS_STOP = 'P2P_UPDATE_ORDER_STATUS_STOP';

export const P2P_BUYER_PAYMENT_CONFIRM_INIT = 'P2P_BUYER_PAYMENT_CONFIRM_INIT';
export const P2P_BUYER_PAYMENT_CONFIRM_SUCCESS =
  'P2P_BUYER_PAYMENT_CONFIRM_SUCCESS';
export const P2P_BUYER_PAYMENT_CONFIRM_ERROR =
  'P2P_BUYER_PAYMENT_CONFIRM_ERROR';

export const P2P_BUYER_PAYMENT_ACCEPTANCE_INIT =
  'P2P_BUYER_PAYMENT_ACCEPTANCE_INIT';
export const P2P_BUYER_PAYMENT_ACCEPTANCE_SUCCESS =
  'P2P_BUYER_PAYMENT_ACCEPTANCE_SUCCESS';
export const P2P_BUYER_PAYMENT_ACCEPTANCE_ERROR =
  'P2P_BUYER_PAYMENT_ACCEPTANCE_ERROR';

export const P2P_SELLER_PAYMENT_RECEIVED_CONFIRM_INIT =
  'P2P_SELLER_PAYMENT_RECEIVED_CONFIRM_INIT';
export const P2P_SELLER_PAYMENT_RECEIVED_CONFIRM_SUCCESS =
  'P2P_SELLER_PAYMENT_RECEIVED_CONFIRM_SUCCESS';
export const P2P_SELLER_PAYMENT_RECEIVED_CONFIRM_ERROR =
  'P2P_SELLER_PAYMENT_RECEIVED_CONFIRM_ERROR';

export const P2P_RAISE_DISPUTE_INIT = 'P2P_RAISE_DISPUTE_INIT';
export const P2P_RAISE_DISPUTE_SUCCESS = 'P2P_RAISE_DISPUTE_SUCCESS';
export const P2P_RAISE_DISPUTE_ERROR = 'P2P_RAISE_DISPUTE_ERROR';

export const SET_USERNAME_INIT = 'SET_USERNAME_INIT';
export const SET_USERNAME_SUCCESS = 'SET_USERNAME_SUCCESS';
export const SET_USERNAME_ERROR = 'SET_USERNAME_ERROR';

export const P2P_FETCH_ORDER_BOOK_INIT = 'P2P_FETCH_ORDER_BOOK_INIT';
export const P2P_FETCH_ORDER_BOOK_SUCCESS = 'P2P_FETCH_ORDER_BOOK_SUCCESS';
export const P2P_FETCH_ORDER_BOOK_ERROR = 'P2P_FETCH_ORDER_BOOK_ERROR';
export const P2P_UPDATE_ORDER_BOOK = 'P2P_UPDATE_ORDER_BOOK';

export const P2P_FETCH_ORDER_BOOK_BY_USERNAME_INIT =
  'P2P_FETCH_ORDER_BOOK_BY_USERNAME_INIT';
export const P2P_FETCH_ORDER_BOOK_BY_USERNAME_SUCCESS =
  'P2P_FETCH_ORDER_BOOK_BY_USERNAME_SUCCESS';
export const P2P_FETCH_ORDER_BOOK_BY_USERNAME_ERROR =
  'P2P_FETCH_ORDER_BOOK_BY_USERNAME_ERROR';
export const P2P_UPDATE_ORDER_BOOK_BY_USERNAME =
  'P2P_UPDATE_ORDER_BOOK_BY_USERNAME';

export const P2P_FETCH_TRADE_HISTORY_INIT = 'P2P_FETCH_TRADE_HISTORY_INIT';
export const P2P_FETCH_TRADE_HISTORY_SUCCESS =
  'P2P_FETCH_TRADE_HISTORY_SUCCESS';
export const P2P_FETCH_TRADE_HISTORY_ERROR = 'P2P_FETCH_TRADE_HISTORY_ERROR';
export const P2P_UPDATE_TRADE_HISTORY = 'P2P_UPDATE_TRADE_HISTORY';

export const P2P_FETCH_P2P_HELP_ARTICLES_INIT =
  'P2P_FETCH_P2P_HELP_ARTICLES_INIT';
export const P2P_FETCH_P2P_HELP_ARTICLES_SUCCESS =
  'P2P_FETCH_P2P_HELP_ARTICLES_SUCCESS';
export const P2P_FETCH_P2P_HELP_ARTICLES_ERROR =
  'P2P_FETCH_P2P_HELP_ARTICLES_ERROR';

export const FETCH_PAYMENT_OPTIONS_INIT = 'FETCH_PAYMENT_OPTIONS_INIT';
export const FETCH_PAYMENT_OPTIONS_SUCCESS = 'FETCH_PAYMENT_OPTIONS_SUCCESS';
export const FETCH_PAYMENT_OPTIONS_ERROR = 'FETCH_PAYMENT_OPTIONS_ERROR';

export const ADD_PAYMENT_OPTION_INIT = 'ADD_PAYMENT_OPTION_INIT';
export const ADD_PAYMENT_OPTION_SUCCESS = 'ADD_PAYMENT_OPTION_SUCCESS';
export const ADD_PAYMENT_OPTION_ERROR = 'ADD_PAYMENT_OPTION_ERROR';

export const REMOVE_PAYMENT_OPTION_INIT = 'REMOVE_PAYMENT_OPTION_INIT';
export const REMOVE_PAYMENT_OPTION_SUCCESS = 'REMOVE_PAYMENT_OPTION_SUCCESS';
export const REMOVE_PAYMENT_OPTION_ERROR = 'REMOVE_PAYMENT_OPTION_ERROR';

export const SET_DEFAULT_PAYMENT_OPTION_INIT =
  'SET_DEFAULT_PAYMENT_OPTION_INIT';
export const SET_DEFAULT_PAYMENT_OPTION_SUCCESS =
  'SET_DEFAULT_PAYMENT_OPTION_SUCCESS';
export const SET_DEFAULT_PAYMENT_OPTION_ERROR =
  'SET_DEFAULT_PAYMENT_OPTION_ERROR';

export const UPDATE_PAYMENT_OPTION_ALIAS_INIT =
  'UPDATE_PAYMENT_OPTION_ALIAS_INIT';
export const UPDATE_PAYMENT_OPTION_ALIAS_SUCCESS =
  'UPDATE_PAYMENT_OPTION_ALIAS_SUCCESS';
export const UPDATE_PAYMENT_OPTION_ALIAS_ERROR =
  'UPDATE_PAYMENT_OPTION_ALIAS_ERROR';

export const RESET_ADD_PAYMENT_OPTIONS_ERROR =
  'RESET_ADD_PAYMENT_OPTIONS_ERROR';
export const RESET_PAYMENT_OPTIONS_2FA_DATA = 'RESET_PAYMENT_OPTIONS_2FA_DATA';

export const FETCH_CURRENCY_PRICES_INIT = 'FETCH_CURRENCY_PRICES_INIT';
export const FETCH_CURRENCY_PRICES_SUCCESS = 'FETCH_CURRENCY_PRICES_SUCCESS';
export const FETCH_CURRENCY_PRICES_LIVE = 'FETCH_CURRENCY_PRICES_LIVE';
export const FETCH_CURRENCY_PRICES_ERROR = 'FETCH_CURRENCY_PRICES_ERROR';

export const FETCH_DEPOSIT_BONUS_INFO_INIT = 'FETCH_DEPOSIT_BONUS_INFO_INIT';
export const FETCH_DEPOSIT_BONUS_INFO_SUCCESS =
  'FETCH_DEPOSIT_BONUS_INFO_SUCCESS';
export const FETCH_DEPOSIT_BONUS_INFO_ERROR = 'FETCH_DEPOSIT_BONUS_INFO_ERROR';
export const FETCH_DEPOSIT_BONUS_CURRENT_INIT =
  'FETCH_DEPOSIT_BONUS_CURRENT_INIT';
export const FETCH_DEPOSIT_BONUS_CURRENT_SUCCESS =
  'FETCH_DEPOSIT_BONUS_CURRENT_SUCCESS';
export const FETCH_DEPOSIT_BONUS_CURRENT_ERROR =
  'FETCH_DEPOSIT_BONUS_CURRENT_ERROR';
export const FETCH_DEPOSIT_BONUS_HISTORY_INIT =
  'FETCH_DEPOSIT_BONUS_HISTORY_INIT';
export const FETCH_DEPOSIT_BONUS_HISTORY_SUCCESS =
  'FETCH_DEPOSIT_BONUS_HISTORY_SUCCESS';
export const FETCH_DEPOSIT_BONUS_HISTORY_ERROR =
  'FETCH_DEPOSIT_BONUS_HISTORY_ERROR';

export const FETCH_NOTIFICATION_PREFERENCES_INIT =
  'FETCH_NOTIFICATION_PREFERENCES_INIT';
export const FETCH_NOTIFICATION_PREFERENCES_SUCCESS =
  'FETCH_NOTIFICATION_PREFERENCES_SUCCESS';
export const FETCH_NOTIFICATION_PREFERENCES_ERROR =
  'FETCH_NOTIFICATION_PREFERENCES_ERROR';

export const SET_NOTIFICATION_PREFERENCES_INIT =
  'SET_NOTIFICATION_PREFERENCES_INIT';
export const SET_NOTIFICATION_PREFERENCES_SUCCESS =
  'SET_NOTIFICATION_PREFERENCES_SUCCESS';
export const SET_NOTIFICATION_PREFERENCES_ERROR =
  'SET_NOTIFICATION_PREFERENCES_ERROR';

export const FETCH_COMMISSION_HISTORY_INIT = 'FETCH_COMMISSION_HISTORY_INIT';
export const FETCH_COMMISSION_HISTORY_SUCCESS =
  'FETCH_COMMISSION_HISTORY_SUCCESS';
export const FETCH_COMMISSION_HISTORY_ERROR = 'FETCH_COMMISSION_HISTORY_ERROR';

export const FETCH_REFERRED_USERS_INIT = 'FETCH_REFERRED_USERS_INIT';
export const FETCH_REFERRED_USERS_SUCCESS = 'FETCH_REFERRED_USERS_SUCCESS';
export const FETCH_REFERRED_USERS_ERROR = 'FETCH_REFERRED_USERS_ERROR';

export const FETCH_TOTAL_REFERRAL_EARNINGS_INIT =
  'FETCH_TOTAL_REFERRAL_EARNINGS_INIT';
export const FETCH_TOTAL_REFERRAL_EARNINGS_SUCCESS =
  'FETCH_TOTAL_REFERRAL_EARNINGS_SUCCESS';
export const FETCH_TOTAL_REFERRAL_EARNINGS_ERROR =
  'FETCH_TOTAL_REFERRAL_EARNINGS_ERROR';

export const FETCH_MARKET_STATUS_INIT = 'FETCH_MARKET_STATUS_INIT';
export const FETCH_MARKET_STATUS_SUCCESS = 'FETCH_MARKET_STATUS_SUCCESS';
export const FETCH_MARKET_STATUS_ERROR = 'FETCH_MARKET_STATUS_ERROR';

export const INIT_2FA_CHANGE_INIT = 'INIT_2FA_CHANGE_INIT';
export const INIT_2FA_CHANGE_SUCCESS = 'INIT_2FA_CHANGE_SUCCESS';
export const INIT_2FA_CHANGE_ERROR = 'INIT_2FA_CHANGE_ERROR';
export const VERIFY_2FA_CHANGE_INIT = 'VERIFY_2FA_CHANGE_INIT';
export const VERIFY_2FA_CHANGE_SUCCESS = 'VERIFY_2FA_CHANGE_SUCCESS';
export const VERIFY_2FA_CHANGE_ERROR = 'VERIFY_2FA_CHANGE_ERROR';
export const CONFIRM_2FA_CHANGE_INIT = 'CONFIRM_2FA_CHANGE_INIT';
export const CONFIRM_2FA_CHANGE_SUCCESS = 'CONFIRM_2FA_CHANGE_SUCCESS';
export const CONFIRM_2FA_CHANGE_ERROR = 'CONFIRM_2FA_CHANGE_ERROR';

export const RESET_2FA_CHANGE = 'RESET_2FA_CHANGE';
export const SET_CURRENT_2FA_CHANGE_STAGE = 'SET_CURRENT_2FA_CHANGE_STAGE';
export const SET_2FA_CHANGE_POPUP = 'SET_2FA_CHANGE_POPUP';

export const FETCH_2FA_MODES_INIT = 'FETCH_2FA_MODES_INIT';
export const FETCH_2FA_MODES_SUCCESS = 'FETCH_2FA_MODES_SUCCESS';
export const FETCH_2FA_MODES_ERROR = 'FETCH_2FA_MODES_ERROR';

export const CONFIRM_EMAIL_INIT = 'CONFIRM_EMAIL_INIT';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR';
export const CONFIRM_EMAIL_RESET = 'CONFIRM_EMAIL_RESET';

export const RECOVER_2FA_INIT = 'RECOVER_2FA_INIT';
export const RECOVER_2FA_SUCCESS = 'RECOVER_2FA_SUCCESS';
export const RECOVER_2FA_ERROR = 'RECOVER_2FA_ERROR';

export const FETCH_USER_ACTIVITY_LOGS_INIT = 'FETCH_USER_ACTIVITY_LOGS_INIT';
export const FETCH_USER_ACTIVITY_LOGS_SUCCESS =
  'FETCH_USER_ACTIVITY_LOGS_SUCCESS';
export const FETCH_USER_ACTIVITY_LOGS_ERROR = 'FETCH_USER_ACTIVITY_LOGS_ERROR';

export const FETCH_KYC_FORMAT_INIT = 'FETCH_KYC_FORMAT_INIT';
export const FETCH_KYC_FORMAT_SUCCESS = 'FETCH_KYC_FORMAT_SUCCESS';
export const FETCH_KYC_FORMAT_ERROR = 'FETCH_KYC_FORMAT_ERROR';

export const FETCH_KYC_NATIVE_STATUS_INIT = 'FETCH_KYC_NATIVE_STATUS_INIT';
export const FETCH_KYC_NATIVE_STATUS_SUCCESS =
  'FETCH_KYC_NATIVE_STATUS_SUCCESS';
export const FETCH_KYC_NATIVE_STATUS_ERROR = 'FETCH_KYC_NATIVE_STATUS_ERROR';

export const FETCH_COUNTRIES_LIST_INIT = 'FETCH_COUNTRIES_LIST_INIT';
export const FETCH_COUNTRIES_LIST_SUCCESS = 'FETCH_COUNTRIES_LIST_SUCCESS';
export const FETCH_COUNTRIES_LIST_ERROR = 'FETCH_COUNTRIES_LIST_ERROR';

export const FETCH_CALLING_CODES_INIT = 'FETCH_CALLING_CODES_INIT';
export const FETCH_CALLING_CODES_SUCCESS = 'FETCH_CALLING_CODES_SUCCESS';
export const FETCH_CALLING_CODES_ERROR = 'FETCH_CALLING_CODES_ERROR';

export const FETCH_PAYMENT_OPTIONS_FORMAT_INIT =
  'FETCH_PAYMENT_OPTIONS_FORMAT_INIT';
export const FETCH_PAYMENT_OPTIONS_FORMAT_SUCCESS =
  'FETCH_PAYMENT_OPTIONS_FORMAT_SUCCESS';
export const FETCH_PAYMENT_OPTIONS_FORMAT_ERROR =
  'FETCH_PAYMENT_OPTIONS_FORMAT_ERROR';

export const SET_PREFERRED_QUOTE_CURRENCY_INIT =
  'SET_PREFERRED_QUOTE_CURRENCY_INIT';
export const SET_PREFERRED_QUOTE_CURRENCY_SUCCESS =
  'SET_PREFERRED_QUOTE_CURRENCY_SUCCESS';
export const SET_PREFERRED_QUOTE_CURRENCY_ERROR =
  'SET_PREFERRED_QUOTE_CURRENCY_ERROR';

export const REQUEST_TRADING_REPORT_INIT = 'REQUEST_TRADING_REPORT_INIT';
export const REQUEST_TRADING_REPORT_SUCCESS = 'REQUEST_TRADING_REPORT_SUCCESS';
export const REQUEST_TRADING_REPORT_ERROR = 'REQUEST_TRADING_REPORT_ERROR';

export const REQUEST_GST_INVOICE_INIT = 'REQUEST_GST_INVOICE_INIT';
export const REQUEST_GST_INVOICE_SUCCESS = 'REQUEST_GST_INVOICE_SUCCESS';
export const REQUEST_GST_INVOICE_ERROR = 'REQUEST_GST_INVOICE_ERROR';

export const FETCH_WRX_BREAKDOWN_INIT = 'FETCH_WRX_BREAKDOWN_INIT';
export const FETCH_WRX_BREAKDOWN_SUCCESS = 'FETCH_WRX_BREAKDOWN_SUCCESS';
export const FETCH_WRX_BREAKDOWN_ERROR = 'FETCH_WRX_BREAKDOWN_ERROR';

export const FETCH_WRX_UNLOCK_SCHEDULE_INIT = 'FETCH_WRX_UNLOCK_SCHEDULE_INIT';
export const FETCH_WRX_UNLOCK_SCHEDULE_SUCCESS =
  'FETCH_WRX_UNLOCK_SCHEDULE_SUCCESS';
export const FETCH_WRX_UNLOCK_SCHEDULE_ERROR =
  'FETCH_WRX_UNLOCK_SCHEDULE_ERROR';

export const FETCH_WRX_UNLOCK_HISTORY_INIT = 'FETCH_WRX_UNLOCK_HISTORY_INIT';
export const FETCH_WRX_UNLOCK_HISTORY_SUCCESS =
  'FETCH_WRX_UNLOCK_HISTORY_SUCCESS';
export const FETCH_WRX_UNLOCK_HISTORY_ERROR = 'FETCH_WRX_UNLOCK_HISTORY_ERROR';

export const OAUTH_LOGIN_INITIATE = 'OAUTH_LOGIN_INITIATE';
export const OAUTH_LOGIN_SUCCESS = 'OAUTH_LOGIN_SUCCESS';
export const OAUTH_LOGIN_ERROR = 'OAUTH_LOGIN_ERROR';
export const OAUTH_LOGIN_FETCH_OAUTH_URL_INIT =
  'OAUTH_LOGIN_FETCH_OAUTH_URL_INIT';
export const OAUTH_LOGIN_FETCH_OAUTH_URL_SUCCESS =
  'OAUTH_LOGIN_FETCH_OAUTH_URL_SUCCESS';
export const OAUTH_LOGIN_FETCH_OAUTH_URL_ERROR =
  'OAUTH_LOGIN_FETCH_OAUTH_URL_ERROR';
export const OAUTH_LOGIN_OPEN_BINANCE_AUTH_INIT =
  'OAUTH_LOGIN_OPEN_BINANCE_AUTH_INIT';
export const OAUTH_LOGIN_OPEN_BINANCE_AUTH_SUCCESS =
  'OAUTH_LOGIN_OPEN_BINANCE_AUTH_SUCCESS';
export const OAUTH_LOGIN_OPEN_BINANCE_AUTH_ERROR =
  'OAUTH_LOGIN_OPEN_BINANCE_AUTH_ERROR';
export const OAUTH_LOGIN_VALIDATE_OAUTH_CODE_INIT =
  'OAUTH_LOGIN_VALIDATE_OAUTH_CODE_INIT';
export const OAUTH_LOGIN_VALIDATE_OAUTH_CODE_SUCCESS =
  'OAUTH_LOGIN_VALIDATE_OAUTH_CODE_SUCCESS';
export const OAUTH_LOGIN_VALIDATE_OAUTH_CODE_ERROR =
  'OAUTH_LOGIN_VALIDATE_OAUTH_CODE_ERROR';

export const SUBMIT_BINANCE_TRANSFER_REQUEST_INIT =
  'SUBMIT_BINANCE_TRANSFER_REQUEST_INIT';
export const SUBMIT_BINANCE_TRANSFER_REQUEST_SUCCESS =
  'SUBMIT_BINANCE_TRANSFER_REQUEST_SUCCESS';
export const SUBMIT_BINANCE_TRANSFER_REQUEST_ERROR =
  'SUBMIT_BINANCE_TRANSFER_REQUEST_ERROR';
export const SUBMIT_BINANCE_TRANSFER_REQUEST_RESET =
  'SUBMIT_BINANCE_TRANSFER_REQUEST_RESET';

export const ASSET_TRANSFER_CURRENCIES_INIT = 'ASSET_TRANSFER_CURRENCIES_INIT';
export const ASSET_TRANSFER_CURRENCIES_SUCCESS =
  'ASSET_TRANSFER_CURRENCIES_SUCCESS';
export const ASSET_TRANSFER_CURRENCIES_ERROR =
  'ASSET_TRANSFER_CURRENCIES_ERROR';

export const FETCH_ADDRESS_BOOKS_INIT = 'FETCH_ADDRESS_BOOKS_INIT';
export const FETCH_ADDRESS_BOOKS_SUCCESS = 'FETCH_ADDRESS_BOOKS_SUCCESS';
export const FETCH_ADDRESS_BOOKS_ERROR = 'FETCH_ADDRESS_BOOKS_ERROR';
export const ADD_SAVED_ADDRESS_INIT = 'ADD_SAVED_ADDRESS_INIT';
export const ADD_SAVED_ADDRESS_SUCCESS = 'ADD_SAVED_ADDRESS_SUCCESS';
export const ADD_SAVED_ADDRESS_ERROR = 'ADD_SAVED_ADDRESS_ERROR';
export const ADD_SAVED_ADDRESS_RESET = 'ADD_SAVED_ADDRESS_RESET';

export const REFRESH_BINANCE_KYC_INIT = 'REFRESH_BINANCE_KYC_INIT';
export const REFRESH_BINANCE_KYC_SUCCESS = 'REFRESH_BINANCE_KYC_SUCCESS';
export const REFRESH_BINANCE_KYC_ERROR = 'REFRESH_BINANCE_KYC_ERROR';

export const FETCH_PREFERENCES_INIT = 'FETCH_PREFERENCES_INIT';
export const FETCH_PREFERENCES_SUCCESS = 'FETCH_PREFERENCES_SUCCESS';
export const FETCH_PREFERENCES_ERROR = 'FETCH_PREFERENCES_ERROR';

export const SET_PREFERENCES_INIT = 'SET_PREFERENCES_INIT';
export const SET_PREFERENCES_SUCCESS = 'SET_PREFERENCES_SUCCESS';
export const SET_PREFERENCES_ERROR = 'SET_PREFERENCES_ERROR';

export const VERIFY_PAYMENT_OPTION_INIT = 'VERIFY_PAYMENT_OPTION_INIT';
export const VERIFY_PAYMENT_OPTION_SUCCESS = 'VERIFY_PAYMENT_OPTION_SUCCESS';
export const VERIFY_PAYMENT_OPTION_ERROR = 'VERIFY_PAYMENT_OPTION_ERROR';

export const LOAD_FAVOURITE_MARKETS_INIT = 'LOAD_FAVOURITE_MARKETS_INIT';
export const LOAD_FAVOURITE_MARKETS_SUCCESS = 'LOAD_FAVOURITE_MARKETS_SUCCESS';
export const LOAD_FAVOURITE_MARKETS_ERROR = 'LOAD_FAVOURITE_MARKETS_ERROR';

export const REMOVE_FAVOURITE_MARKET = 'REMOVE_FAVOURITE_MARKET';
export const ADD_FAVOURITE_MARKET = 'ADD_FAVOURITE_MARKET';

export const FETCH_REDEEMED_COUPONS_INIT = 'FETCH_REDEEMED_COUPONS_INIT';
export const FETCH_REDEEMED_COUPONS_SUCCESS = 'FETCH_REDEEMED_COUPONS_SUCCESS';
export const FETCH_REDEEMED_COUPONS_ERROR = 'FETCH_REDEEMED_COUPONS_ERROR';
export const CLAIM_COUPONS_INIT = 'CLAIM_COUPONS_INIT';
export const CLAIM_COUPONS_SUCCESS = 'CLAIM_COUPONS_SUCCESS';
export const CLAIM_COUPONS_ERROR = 'CLAIM_COUPONS_ERROR';

export const INITIATE_PAYMENT_GATEWAY_DEPOSIT_INIT =
  'INITIATE_PAYMENT_GATEWAY_DEPOSIT_INIT';
export const INITIATE_PAYMENT_GATEWAY_DEPOSIT_SUCCESS =
  'INITIATE_PAYMENT_GATEWAY_DEPOSIT_SUCCESS';
export const INITIATE_PAYMENT_GATEWAY_DEPOSIT_ERROR =
  'INITIATE_PAYMENT_GATEWAY_DEPOSIT_ERROR';

export const FETCH_DEPOSIT_TRANSACTION_INFO_START =
  'FETCH_DEPOSIT_TRANSACTION_INFO_START';
export const FETCH_DEPOSIT_TRANSACTION_INFO_SUCCESS =
  'FETCH_DEPOSIT_TRANSACTION_INFO_SUCCESS';
export const FETCH_DEPOSIT_TRANSACTION_INFO_STOP =
  'FETCH_DEPOSIT_TRANSACTION_INFO_STOP';

export const REGISTER_PHONE_INIT = 'REGISTER_PHONE_INIT';
export const REGISTER_PHONE_SUCCESS = 'REGISTER_PHONE_SUCCESS';
export const REGISTER_PHONE_ERROR = 'REGISTER_PHONE_ERROR';

export const FETCH_API_KEYS_INIT = 'FETCH_API_KEYS_INIT';
export const FETCH_API_KEYS_SUCCESS = 'FETCH_API_KEYS_SUCCESS';
export const FETCH_API_KEYS_ERROR = 'FETCH_API_KEYS_ERROR';

export const CREATE_NEW_API_KEY_INIT = 'CREATE_NEW_API_KEY_INIT';
export const CREATE_NEW_API_KEY_SUCCESS = 'CREATE_NEW_API_KEY_SUCCESS';
export const CREATE_NEW_API_KEY_ERROR = 'CREATE_NEW_API_KEY_ERROR';
export const CREATE_NEW_API_KEY_RESET = 'CREATE_NEW_API_KEY_RESET';
export const SET_NEW_API_KEY_IN_LIST = 'SET_NEW_API_KEY_IN_LIST';
export const UPDATE_API_KEY_INIT = 'UPDATE_API_KEY_INIT';
export const UPDATE_API_KEY_SUCCESS = 'UPDATE_API_KEY_SUCCESS';
export const UPDATE_API_KEY_ERROR = 'UPDATE_API_KEY_ERROR';
export const UPDATE_API_KEY_IN_LIST = 'UPDATE_API_KEY_IN_LIST';

export const DELETE_API_KEY_INIT = 'DELETE_API_KEY_INIT';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_ERROR = 'DELETE_API_KEY_ERROR';

export const DELETE_ALL_API_KEYS_INIT = 'DELETE_ALL_API_KEYS_INIT';
export const DELETE_ALL_API_KEYS_SUCCESS = 'DELETE_ALL_API_KEYS_SUCCESS';
export const DELETE_ALL_API_KEYS_ERROR = 'DELETE_ALL_API_KEYS_ERROR';

export const GET_DUSTS_INIT = 'GET_DUSTS_INIT';
export const GET_DUSTS_SUCCESS = 'GET_DUSTS_SUCCESS';
export const GET_DUSTS_ERROR = 'GET_DUSTS_ERROR';

export const CONVERT_DUSTS_INIT = 'CONVERT_DUSTS_INIT';
export const CONVERT_DUSTS_SUCCESS = 'CONVERT_DUSTS_SUCCESS';
export const CONVERT_DUSTS_ERROR = 'CONVERT_DUSTS_ERROR';

export const FETCH_CURRENCY_NETWORKS_INIT = 'FETCH_CURRENCY_NETWORKS_INIT';
export const FETCH_CURRENCY_NETWORKS_SUCCESS =
  'FETCH_CURRENCY_NETWORKS_SUCCESS';
export const FETCH_CURRENCY_NETWORKS_ERROR = 'FETCH_CURRENCY_NETWORKS_ERROR';
export const FETCH_LIVE_CURRENCY_NETWORKS = 'FETCH_LIVE_CURRENCY_NETWORKS';

export const OTC_PLACE_ORDER_INIT = 'OTC_PLACE_ORDER_INIT';
export const OTC_PLACE_ORDER_SUCCESS = 'OTC_PLACE_ORDER_SUCCESS';
export const OTC_SET_PLACED_ORDER_ID = 'OTC_SET_PLACED_ORDER_ID';
export const OTC_RESET_PLACED_ORDER_ID = 'OTC_RESET_PLACED_ORDER_ID';
export const OTC_PLACE_ORDER_ERROR = 'OTC_PLACE_ORDER_ERROR';
export const OTC_FETCH_PRICE_INIT = 'OTC_FETCH_PRICE_INIT';
export const OTC_FETCH_PRICE_SUCCESS = 'OTC_FETCH_PRICE_SUCCESS';
export const OTC_FETCH_PRICE_ERROR = 'OTC_FETCH_PRICE_ERROR';

export const OTC_FETCH_ORDERS_INIT = 'OTC_FETCH_ORDERS_INIT';
export const OTC_FETCH_ORDERS_SUCCESS = 'OTC_FETCH_ORDERS_SUCCESS';
export const OTC_FETCH_ORDERS_ERROR = 'OTC_FETCH_ORDERS_ERROR';
export const OTC_FETCH_ORDERS_LIVE = 'OTC_FETCH_ORDERS_LIVE';
export const OTC_FETCH_ORDERS_NEXT = 'OTC_FETCH_ORDERS_NEXT';

export const OTC_FETCH_ORDER_DETAIL_INIT = 'OTC_FETCH_ORDER_DETAIL_INIT';
export const OTC_FETCH_ORDER_DETAIL_SUCCESS = 'OTC_FETCH_ORDER_DETAIL_SUCCESS';
export const OTC_FETCH_ORDER_DETAIL_ERROR = 'OTC_FETCH_ORDER_DETAIL_ERROR';

export const OTC_CANCEL_ORDER_INIT = 'OTC_CANCEL_ORDER_INIT';
export const OTC_CANCEL_ORDER_SUCCESS = 'OTC_CANCEL_ORDER_SUCCESS';
export const OTC_CANCEL_ORDER_ERROR = 'OTC_CANCEL_ORDER_ERROR';

export const OTC_UPDATE_ORDER_STATUS = 'OTC_UPDATE_ORDER_STATUS';
export const OTC_UPDATE_ORDER_STATUS_START = 'OTC_UPDATE_ORDER_STATUS_START';
export const OTC_UPDATE_ORDER_STATUS_STOP = 'OTC_UPDATE_ORDER_STATUS_STOP';

export const FETCH_SUBACCOUNT_ACCOUNTS_INIT = 'FETCH_SUBACCOUNT_ACCOUNTS_INIT';
export const FETCH_SUBACCOUNT_ACCOUNTS_SUCCESS =
  'FETCH_SUBACCOUNT_ACCOUNTS_SUCCESS';
export const FETCH_SUBACCOUNT_ACCOUNTS_ERROR =
  'FETCH_SUBACCOUNT_ACCOUNTS_ERROR';

export const FETCH_SUBACCOUNT_TRANSFER_HISTORY_INIT =
  'FETCH_SUBACCOUNT_TRANSFER_HISTORY_INIT';
export const FETCH_SUBACCOUNT_TRANSFER_HISTORY_SUCCESS =
  'FETCH_SUBACCOUNT_TRANSFER_HISTORY_SUCCESS';
export const FETCH_SUBACCOUNT_TRANSFER_HISTORY_ERROR =
  'FETCH_SUBACCOUNT_TRANSFER_HISTORY_ERROR';

export const CREATE_SUBACCOUNT_SUCCESS = 'CREATE_SUBACCOUNT_SUCCESS';
export const ACTION_SUBACCOUNT_SUCCESS = 'ACTION_SUBACCOUNT_SUCCESS';

export const FETCH_DEPOSITS_INIT = 'FETCH_DEPOSITS_INIT';
export const FETCH_DEPOSITS_SUCCESS = 'FETCH_DEPOSITS_SUCCESS';
export const FETCH_DEPOSITS_ERROR = 'FETCH_DEPOSITS_ERROR';
export const FETCH_DEPOSITS_PAYMENT_OPTIONS_INIT =
  'FETCH_DEPOSITS_PAYMENT_OPTIONS_INIT';
export const FETCH_DEPOSITS_PAYMENT_OPTIONS_SUCCESS =
  'FETCH_DEPOSITS_PAYMENT_OPTIONS_SUCCESS';
export const FETCH_DEPOSITS_PAYMENT_OPTIONS_ERROR =
  'FETCH_DEPOSITS_PAYMENT_OPTIONS_ERROR';
export const FETCH_DEPOSITS_FEES_INIT = 'FETCH_DEPOSITS_FEES_INIT';
export const FETCH_DEPOSITS_FEES_SUCCESS = 'FETCH_DEPOSITS_FEES_SUCCESS';
export const FETCH_DEPOSITS_FEES_ERROR = 'FETCH_DEPOSITS_FEES_ERROR';

export const FETCH_PORTFOLIO_INIT = 'FETCH_PORTFOLIO_INIT';
export const FETCH_PORTFOLIO_SUCCESS = 'FETCH_PORTFOLIO_SUCCESS';
export const FETCH_PORTFOLIO_ERROR = 'FETCH_PORTFOLIO_ERROR';

export const P2P_SUBMIT_PAYMENT_PROOF_INIT = 'P2P_SUBMIT_PAYMENT_PROOF_INIT';
export const P2P_SUBMIT_PAYMENT_PROOF_SUCCESS =
  'P2P_SUBMIT_PAYMENT_PROOF_SUCCESS';
export const P2P_SUBMIT_PAYMENT_PROOF_ERROR = 'P2P_SUBMIT_PAYMENT_PROOF_ERROR';

export const FETCH_RECEIVED_GIFTS_INIT = 'FETCH_RECEIVED_GIFTS_INIT';
export const FETCH_RECEIVED_GIFTS_SUCCESS = 'FETCH_RECEIVED_GIFTS_SUCCESS';
export const FETCH_RECEIVED_GIFTS_ERROR = 'FETCH_RECEIVED_GIFTS_ERROR';

export const FETCH_SENT_GIFTS_INIT = 'FETCH_SENT_GIFTS_INIT';
export const FETCH_SENT_GIFTS_SUCCESS = 'FETCH_SENT_GIFTS_SUCCESS';
export const FETCH_SENT_GIFTS_ERROR = 'FETCH_SENT_GIFTS_ERROR';

export const FETCH_GIFT_DETAILS_INIT = 'FETCH_GIFT_DETAILS_INIT';
export const FETCH_GIFT_DETAILS_SUCCESS = 'FETCH_GIFT_DETAILS_SUCCESS';
export const FETCH_GIFT_DETAILS_ERROR = 'FETCH_GIFT_DETAILS_ERROR';

export const FETCH_ACCEPT_REJECT_GIFTS_INIT = 'FETCH_ACCEPT_REJECT_GIFTS_INIT';
export const FETCH_ACCEPT_REJECT_GIFTS_SUCCESS =
  'FETCH_ACCEPT_REJECT_GIFTS_SUCCESS';
export const FETCH_ACCEPT_REJECT_GIFTS_RESET =
  'FETCH_ACCEPT_REJECT_GIFTS_RESET';
export const FETCH_ACCEPT_REJECT_GIFTS_ERROR =
  'FETCH_ACCEPT_REJECT_GIFTS_ERROR';
